import { AppLauncher } from '@capacitor/app-launcher'
import { Capacitor } from '@capacitor/core'

import { makeWhatsappMessage } from '~/utils/whatsapp.util'

const useWhatsapp = (whatsappNumber: string) => {
  const platform = Capacitor.getPlatform()

  const open = (message: string = '') => {
    switch (platform) {
      case 'android':
      case 'ios': {
        _openNative(message)
        break
      }
      default: {
        _openWeb(message)
        break
      }
    }
  }

  const canOpen = async (url: string) => {
    const { value: canOpen } = await AppLauncher.canOpenUrl({
      url,
    })

    return canOpen
  }

  const _openNative = async (message: string) => {
    const url = `whatsapp://send?phone=${whatsappNumber}&text=${message}`
    const open = await canOpen(platform === 'android' ? 'com.whatsapp' : url)

    if (open) {
      await AppLauncher.openUrl({ url })
    } else {
      _openWeb(message)
    }
  }

  const _openWeb = (message: string) => {
    const link = makeWhatsappMessage(message)

    window.open(link, '_blank')
  }

  return {
    open,
    canOpen,
  }
}

export default useWhatsapp
